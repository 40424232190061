<template>
  <div class="widgetContainer cardSuccess">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          Card Created
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>
    <div class="widgetContainer__body center-content">
      <LottieAnimation animation-name="success" reference="created-card-success" :loop="true" />
      <div>
        <p class="header-title">
          {{
            $t(
              getSelectedCard.cardType === 'physical'
                ? 'physicalCardSuccess_title'
                : 'virtualCardSuccess_title'
            )
          }}
        </p>
        <div class="cardSuccess__details">
          <p>
            <span>{{ $t('card_label') }}</span>
            <span>{{ getSelectedCard.label }}</span>
          </p>
          <p>
            <span>{{ $t('card_spendLimit') }}</span>
            <span>
              {{ getSelectedCard.limitAmount | currency }}
              {{ ' ' + spendingLimit }}
            </span>
          </p>
          <p>
            <span>{{ $t('card_status') }}</span>
            <span>{{ status }}</span>
          </p>
        </div>
        <p class="note" v-if="getSelectedCard.cardType === 'physical'">
          {{ $t('cardSuccess_desc') }}
        </p>
      </div>
    </div>
    <div class="el-drawer__footer">
      <el-button data-testid="submit" class="done-btn el-button__brand brand" @click="done">
        {{ $t('done') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { cardLimit, cardStatus } from '../enums/index';
import LottieAnimation from '@/components/LottieAnimation.vue';

export default {
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters('card', ['getSelectedCard']),
    spendingLimit() {
      return cardLimit[this.getSelectedCard.limitInterval];
    },
    status() {
      return cardStatus[this.getSelectedCard.cardStatus];
    }
  },
  created() {
    this.$emit('afterCreated');
  },
  methods: {
    done() {
      if(this.getSelectedCard.cardType === 'physical') {
        this.drawerClose();
      } else {
        this.$emit('goToDetailsScreen', this.getSelectedCard.cardStatus === 'pendingActivation' ? 'activate' : 'manage');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.cardSuccess {
  background: white;

  .welcome__illustration {
    width: 100%;
    max-width: 180px;
    margin: 0 auto;
  }

  .header-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 16px;
  }
  &__details {
    background: #fff;
    border-radius: var(--radius);
    padding: 10px 16px;
    border: 1px solid #edeff1;
    p {
      font-size: 14px;
      font-weight: 400;
      display: flex;
      margin-bottom: 17px;
      justify-content: space-between;
      color: #767676;
      span:last-child {
        font-weight: 500;
        color: var(--branding);
      }
    }
  }
  .done-btn {
    color: #fff;
    width: 100%;
  }
  .note {
    //styleName: Rubik/Body 2;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(60, 60, 67, 0.6);
    margin-top: 16px;
  }
}
</style>
